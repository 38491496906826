import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
// import { GetBadgeImageFeatureFlag } from './__generated__/GetBadgeImageFeatureFlag';

type FeatureFlagOutput = {
  enabled?: boolean;
};

const GET_BADGE_IMAGE_ENABLED_FEATURE_FLAG = gql`
  query GetBadgeImageFeatureFlag {
    isFeatureFlagEnabledForDeviceOrg(flagType: VISITOR_BADGE_IMAGES)
  }
`;

export const useBadgeImageFeatureFlag: () => FeatureFlagOutput = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = useQuery<any>(GET_BADGE_IMAGE_ENABLED_FEATURE_FLAG);

  return {
    enabled: data?.isFeatureFlagEnabledForDeviceOrg ?? false,
  };
};
